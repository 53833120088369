import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sartlar-ve-kosullar"} />
		<Helmet>
			<title>
				Knockout Academy
			</title>
			<meta name={"description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:title"} content={"Knockout Academy"} />
			<meta property={"og:description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Headernew />
		<Section padding="80px 0" sm-padding="40px 0" color="--light">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Şartlar ve Koşullar{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}Knockout Academy olarak, sunduğumuz hizmetlerin kullanımına ilişkin aşağıdaki şartlar ve koşulları belirledik. Web sitemizi veya hizmetlerimizi kullanarak, bu şartları kabul etmiş sayılırsınız. Lütfen dikkatlice okuyunuz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Üyelik ve Kayıt{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy'de üyelik oluşturmak için geçerli bir kimlik, iletişim bilgileri ve diğer gerekli bilgileri sağlamanız gerekmektedir. Üyelik başvurularınız incelendikten sonra onaylanır ve onaylanmayan başvurular hakkında bilgi verilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Ödeme ve Ücretler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy'nin sunduğu hizmetler için belirlenen ücretler, kayıt sırasında veya belirli dönemlerde tahsil edilir. Ödemelerinizin zamanında yapılması gerekmektedir. Ödeme yapılmaması durumunda üyeliğiniz askıya alınabilir veya iptal edilebilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İptal ve İade Politikası{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Üyelik iptalleri, kayıt tarihinden itibaren belirli bir süre içinde yapılmalıdır. İptal talepleriniz için iade politikamız geçerlidir. İade koşulları ve süreleri hakkında detaylı bilgi için müşteri hizmetleri ile iletişime geçiniz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Kullanıcı Davranışları{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy'de sunulan hizmetleri kullanırken, diğer üyelerimize ve personelimize saygılı davranmanız beklenmektedir. Zarar verici, tehdit edici veya uygunsuz davranışlar kesinlikle yasaktır. Bu tür davranışlar üyeliğinizin iptal edilmesine neden olabilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Sorumluluk Reddi{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy'de katıldığınız tüm aktiviteler ve antrenmanlar kendi sorumluluğunuzdadır. Knockout Academy, bu aktiviteler sırasında meydana gelebilecek yaralanmalar veya sağlık sorunlarından sorumlu tutulamaz. Antrenmanlara başlamadan önce, kendi sağlık durumunuzu göz önünde bulundurmalı ve gerekli önlemleri almalısınız.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Fikri Mülkiyet Hakları{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy web sitesi ve içerikleri, telif hakkı ve diğer fikri mülkiyet hakları ile korunmaktadır. Sitede yer alan hiçbir içerik izinsiz kopyalanamaz, dağıtılamaz veya ticari amaçla kullanılamaz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Değişiklikler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy, bu Şartlar ve Koşullar'ı zaman zaman güncelleme hakkını saklı tutar. Yapılan değişiklikler, bu sayfada yayınlandığı andan itibaren geçerlidir. Güncellemelerden haberdar olmak için bu sayfayı periyodik olarak ziyaret etmenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu Şartlar ve Koşullar hakkında herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçin:{" "}
				<Link href="mailto:info@knockoutacademy.com">
					info@knockoutacademy.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});